<template>
    <div
        :class="{
            control_error: error,
            label_has_link: labelHasLink
        }"
    >
        <label :for="id">
            <span
                :class="{
                    focus_container: true,
                    focus_container_focused: focused,
                    focus_container_checked: isChecked
                }"
            >
                <span
                    :class="{
                        checkbox__control: true,
                        control_checked: isChecked,
                        control_focused: focused,
                        input_disabled: disabled
                    }"
                >
                    <input
                        :id="id"
                        v-model="localValueState"
                        :name="name"
                        :value="value"
                        type="checkbox"
                        :disabled="disabled"
                        class="input"
                        @focus="focus"
                        @blur="blur"
                        @invalid="onInvalid($event)"
                    >
                    <span :class="{ checkbox: true }">
                        <check-svg
                            aria-hidden="true"
                            focusable="false"
                            :class="{ box: true, checked: isChecked, disabled: disabled }"
                        />
                        <minus-svg
                            aria-hidden="true"
                            focusable="false"
                            :class="{ box: true, minus: true }"
                        />
                    </span>
                </span>
            </span>
            <span v-if="!!$slots?.label && !labelHasLink">
                <slot name="label" />
            </span>
            <span
                v-else-if="!labelHasLink"
                :class="{
                    label__text: true,
                    [mode]: true,
                    'label__text--checked': isChecked,
                    'label__table-header': isTableHeader
                }"
            >
                <!-- eslint-disable vue/no-v-html-->
                <span
                    v-if="!description && !boldItem"
                    v-html="label"
                />
                <!-- eslint-enable vue/no-v-html -->
                <b v-if="description || boldItem">{{ label }}</b>
                <slot name="description">
                    <span
                        v-if="description"
                        class="description__text"
                    >
                        {{ description }}
                    </span>
                </slot>
            </span>
        </label>
        <span
            v-if="!!$slots?.label && labelHasLink"
            :class="{ is_label_with_link: labelHasLink }"
        >
            <slot name="label" />
        </span>
        <span
            v-if="labelHasLink"
            :class="{
                label__text: true,
                [mode]: true,
                'label__table-header': isTableHeader
            }"
        >
            <span v-if="!description && !boldItem">{{ label }}</span>
            <b v-if="description || boldItem">{{ label }}</b>
            <slot name="description">
                <span
                    v-if="description"
                    class="description__text"
                >
                    {{ description }}
                </span>
            </slot>
        </span>
        <!-- eslint-disable vue/no-v-html-->
        <span
            v-if="state.hasError"
            class="control_error__message"
            v-html="error"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive, watch } from 'vue';
import { v4 as uuid } from 'uuid';
import CheckSvg from '@bx-icons/regular/bx-check.svg';
import MinusSvg from '@bx-icons/regular/bx-minus.svg';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    value: {
        type: String,
        required: false,
        default: 'on'
    },
    label: {
        type: String,
        required: false,
        default: ''
    },
    description: {
        type: String,
        required: false,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    /**
     * Used by vue to bind the checkbox check state to `v-model` set on the component.
     */
    modelValue: {
        type: [Boolean, Array<String>],
        default: false,
    },
    isTableHeader: {
        type: Boolean,
        default: false,
    },
    mode: {
        type: String,
        default: 'light',
        validator: (value: string) => {
            // You can add more modes types here
            return [
                'light',
                'dark',
            ].includes(value)
        },
    },
    boldItem: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: ''
    },
    labelHasLink: {
        type: Boolean,
        default: false,
    }
})

const id = uuid()

const focused = ref(false)

const emit = defineEmits(['update:model-value'])

const localValueState = computed({
    get() {
        return props.modelValue
    },
    set(newValue) {
        emit('update:model-value', newValue);
    }
})

const isChecked = computed(() => {
    return Array.isArray(localValueState.value) ?
        localValueState.value.includes(props.value) :
        localValueState.value
})

const focus = () => {
    focused.value = true
}

const blur = () => {
    focused.value = false
}

const onInvalid = (event) => {
    state.hasError = true
}

const state = reactive({
    hasError: false
})

watch(() => props.error, () => {
    state.hasError = props.error !== ''
}, { immediate: true } )
</script>

<style scoped>
.input {
    opacity: 0;
    width: 0;
    height: 0;
    grid-area: checkbox;
}

label {
    display: inline-flex;
    gap: 0.5em;
    cursor: pointer;
    align-items: start;
    font-family: unset;
}

.checkbox__control {
    display: grid;
    grid-template-areas: "checkbox";
    width: 1rem;
    height: 1rem;
    border-radius: 0.125em;
    border: .0625rem solid var(--zumba-gray-200);
    overflow: hidden;
    margin: .125em 0 0 .125em;
    justify-content: center;
}

.checkbox__control.control_checked {
    border-color: var(--zumba-plum);
    background-color: var(--zumba-plum);
}
.checkbox__control.control_checked.control_focused {
    border-color: var(--zumba-plum);
}

.checkbox__control.control_focused {
    border-color: var(--zumba-gray-200);
}

.checkbox__control.input_disabled {
    background-color: var(--zumba-gray-200);
    border-color: var(--zumba-gray-200);
}

.control_error .checkbox__control {
    border: .0625rem solid var(--zumba-error-red);
}

.control_error .control_error__message {
    color: var(--zumba-error-red);
    font-size: .75rem;
    display: block;
}

.checkbox {
    grid-area: checkbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    height: .5em;
    width: .5em;
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
}

.box.disabled {
    fill: var(--zumba-gray-500);
}

.minus {
    display: none;
}

.checked {
    transform: scale(2);
    fill: var(--zumba-white);
}

.label__text {
    font-size: 0.875rem;
    letter-spacing: 0;
    color: var(--zumba-gray-800);
    display: inline-block;
    margin-top: .16rem;
}

.theme-zumba .label__text {
    font-family: 'Inter';
}

.label__text--checked {
    color: var(--zumba-gray-800);
}

.label__table-header {
    color: var(--zumba-gray-500);
}

.description__text {
    display: block;
}

.focus_container {
    padding-right: .125rem;
    padding-bottom: .125rem;
    border-radius: .25em;
}

/* Strong theme */

.theme-strong .checked,
.theme-strong .some-selected .minus {
    background-color: #FF0061;
}

.label__text.dark {
    color: var(--zumba-white);
}

.label_has_link {
    display: inline-flex;
    column-gap: 0.5rem;
    flex-wrap: wrap;
}

.label_has_link .is_label_with_link {
    flex: 1 0 85%;
}

.label_has_link .control_error__message {
    flex-basis: 100%;
}

@media screen and (min-width: 48rem) {
    .label_has_link .is_label_with_link {
        flex: 1 0 94%;
    }
}
</style>
