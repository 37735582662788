<template>
    <div>
        <a
            v-if="href !== ''"
            :href="href"
            :target="target"
            :class="classes"
            @click="handleClick"
        >
            <span class="button-icon">
                <slot name="icon" />
            </span>
            <span class="button-label">
                <slot />
            </span>
            <span class="button-counter">
                <slot name="counter" />
            </span>
        </a>
        <button
            v-else
            class="z-button"
            :class="classes"
            :disabled="disabled"
            @click="handleClick"
        >
            <span class="button-icon">
                <slot name="icon" />
            </span>
            <span class="button-label">
                <slot />
            </span>
            <span class="button-counter">
                <slot name="counter" />
            </span>
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
    href: {
        type: String,
        default: '',
    },
    target: {
        type: String,
        default: '_self',
        validator: (value: string) => {
            return ['_self', '_blank', '_parent', '_top'].includes(value)
        },
    },
    variety: {
        type: String,
        default: 'optimus',
        validator: (value: string) => {
            // You can add more variety types here
            return [
                'optimus',
                'robin',
                'phoenix',
                'cyclops',
                'rogue',
                'love',
                'plum',
                'magneto',
                'punisher',
                'joker',
            ].includes(value)
        },
    },
    mode: {
        type: String,
        default: 'light',
        validator: (value: string) => {
            // You can add more modes types here
            return [
                'light',
                'dark',
            ].includes(value)
        },
    },
    wide: {
        type: String,
        default: 'always',
        validator: (value: string) => {
            return [
                'never',
                'always',
                'mobile-only',
                'desktop-only',
            ].includes(value)
        },
    },
    compact: {
        type: String,
        default: 'never',
        validator: (value: string) => {
            return [
                'never',
                'always',
                'mobile-only',
                'desktop-only',
            ].includes(value)
        },
    },
    superCompact: {
        type: String,
        default: 'never',
        validator: (value: string) => {
            return [
                'never',
                'always',
                'mobile-only',
                'desktop-only',
            ].includes(value)
        },
    },
    rounded: {
        type: String,
        default: 'never',
        validator: (value: string) => {
            return [
                'never',
                'always',
                'mobile-only',
                'desktop-only',
            ].includes(value)
        },
    },
    ghost: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    textLink: {
        type: Boolean,
        default: false,
    },
    rated: {
        type: Boolean,
        default: false,
    },
    minion: {
        type: Boolean,
        default: false,
    },
    isIconOnly: {
        type: Boolean,
        default: false,
    }
})

const emit = defineEmits(['click'])

const classes = computed(() => {
    return {
        [props.variety] : true,
        [props.mode] : true,
        ['wide-' + props.wide]: true,
        ['compact-' + props.compact]: true,
        ['super-compact-' + props.superCompact]: true,
        ['rounded-' + props.rounded]: true,
        ghost: props.ghost,
        disabled: props.disabled,
        anchor: !props.textLink,
        ['text-link']: props.textLink,
        ['class-rated']: props.rated,
        minion: props.minion,
        ['icon-only']: props.isIconOnly
    }
})

const handleClick = (e: Event) => {
    if (props.disabled) {
        e.preventDefault();
        e.stopImmediatePropagation();
        return;
    }
    emit('click', e)
}
</script>

<style scoped>
    /* Common */
    .button-icon,
    .button-label,
    .button-counter {
        display: contents;
    }

    .z-button,
    .anchor {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        white-space: nowrap;
        height: 3.125rem;
        max-width: fit-content;
        text-decoration: none;
        border-radius: 0;
        padding: 0 2.25rem;
        border: 1px solid;
        cursor: pointer;
        transition: background-color 300ms ease-out, border-color 300ms ease-out, color 300ms ease-out;
        box-sizing: border-box;
    }

    .theme-zumba .rogue.disabled.class-rated,
    .theme-strong .rogue.disabled.class-rated {
        color: var(--zumba-gray-400);
        display: block;
    }

    .wide-always {
        max-width: unset;
        width: 100%;
    }
    @media only screen and (max-width: 48rem) {
        .wide-mobile-only {
            max-width: unset;
            width: 100%;
        }
    }
    @media only screen and (min-width: 48rem) {
        .wide-desktop-only {
            max-width: unset;
            width: 100%;
        }
    }

    .minion {
        height: 2.5rem;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.25rem;
        border-radius: 0;
        padding: 0 1.5rem;
    }

    .icon-only {
        padding: 0 0.75rem;
    }

    .minion.icon-only {
        padding: 0 0.438rem;
    }

    /* Zumba theme */

    /* Optimus */
    .theme-zumba .optimus {
        background-color: var(--zumba-plum);
        color: var(--zumba-white);
        fill: var(--zumba-white);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .optimus:hover {
        background-color: var(--zumba-gray-800);
        border-color: var(--zumba-gray-800);
        color: var(--zumba-white);
        fill: var(--zumba-white);
    }
    .theme-zumba .optimus.disabled {
        background-color: var(--zumba-gray-400);
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-200);
        fill: var(--zumba-gray-200);
    }

    .theme-zumba .optimus.dark {
        background-color: var(--zumba-white);
        border-color: var(--zumba-white);
        color: var(--zumba-gray-800);
    }
    .theme-zumba .optimus.dark:hover {
        background-color: var(--zumba-plum);
        border-color: var(--zumba-plum);
        color: var(--zumba-white);
    }
    .theme-zumba .optimus.dark.disabled {
        background-color: var(--zumba-gray-600);
        border-color: var(--zumba-gray-600);
        color: var(--zumba-gray-400);
    }

    /* Robin */
    .theme-zumba .robin {
        background-color: var(--zumba-neon-green);
        border-color: var(--zumba-neon-green);
        color: var(--zumba-gray-800);
        fill: var(--zumba-gray-800);
    }
    .theme-zumba .robin:hover {
        background-color: var(--zumba-gray-800);
        border-color: var(--zumba-gray-800);
        color: var(--zumba-white);
        fill: var(--zumba-white);
    }
    .theme-zumba .robin.disabled {
        background-color: var(--zumba-gray-400);
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-200);
        fill: var(--zumba-gray-200);
    }

    /* Phoenix */
    .theme-zumba .phoenix {
        background-color: transparent;
        color: var(--zumba-plum);
        fill: var(--zumba-plum);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .phoenix:hover {
        background-color: var(--zumba-plum);
        color: var(--zumba-white);
        fill: var(--zumba-white);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .phoenix.disabled {
        background-color: transparent;
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-400);
        fill: var(--zumba-gray-400);
    }

    .theme-zumba .phoenix.dark {
        background-color: var(--zumba-gray-800);
        color: var(--zumba-white);
        border-color: var(--zumba-white);
    }
    .theme-zumba .phoenix.dark:hover {
        background-color: var(--zumba-white);
        color: var(--zumba-gray-800);
    }
    .theme-zumba .phoenix.dark.disabled {
        background-color: var(--zumba-white);
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-300);
    }

    /* Cyclops */
    .theme-zumba .z-button.cyclops {
        background-color: transparent;
        color: var(--zumba-gray-800);
        border-color: var(--zumba-gray-800);
    }
    .theme-zumba .cyclops:hover {
        background-color: var(--zumba-gray-800);
        color: var(--zumba-white);
        fill: var(--zumba-white);
    }
    .theme-zumba .cyclops.disabled {
        background-color: transparent;
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-400);
        fill: var(--zumba-gray-400);
    }

    .theme-zumba .cyclops.dark {
        background-color: var(--zumba-gray-800);
        color: var(--zumba-white);
        border-color: var(--zumba-white);
    }
    .theme-zumba .cyclops.dark:hover {
        background-color: var(--zumba-neon-green);
        border-color: var(--zumba-neon-green);
        color: var(--zumba-gray-800);
    }
    .theme-zumba .cyclops.dark.disabled {
        background-color: var(--zumba-gray-800);
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-300);
    }

    /* Rogue */
    .theme-zumba .rogue {
        background-color: var(--zumba-gray-100);
        border: 1px solid var(--zumba-gray-100);
        color: var(--zumba-plum);
        fill: var(--zumba-plum);
    }
    .theme-zumba .rogue:hover {
        background-color: var(--zumba-gray-800);
        color: var(--zumba-white);
        fill: var(--zumba-white);
    }
    .theme-zumba .rogue.disabled {
        background-color: var(--zumba-gray-100);
        color: var(--zumba-gray-400);
        fill: var(--zumba-gray-400);
    }

    /* Love */
    .theme-zumba .love {
        background-color: var(--zumba-white);
        color: var(--zumba-plum);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .love:hover {
        background-color: var(--zumba-plum);
        color: var(--zumba-white);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .love.disabled {
        background-color: var(--zumba-white);
        border-color: var(--zumba-gray-800);
        color: var(--zumba-gray-800);
    }

    .theme-zumba .love.dark {
        background-color: var(--zumba-gray-800);
        color: var(--zumba-white);
        border-color: var(--zumba-white);
    }
    .theme-zumba .love.dark:hover {
        background-color: var(--zumba-gray-800);
        color: var(--zumba-plum);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .love.dark.disabled {
        background-color: var(--zumba-gray-800);
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-300);
    }

    /* Plum */
    .theme-zumba .plum {
        background-color: var(--zumba-white);
        color: var(--zumba-plum);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .plum:hover {
        background-color: var(--zumba-plum);
        color: var(--zumba-white);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .plum.disabled {
        background-color: var(--zumba-white);
        border-color: var(--zumba-gray-800);
        color: var(--zumba-gray-800);
    }

    .theme-zumba .plum.dark {
        background-color: var(--zumba-plum);
        color: var(--zumba-white);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .plum.dark:hover {
        background-color: var(--zumba-plum);
        color: var(--zumba-white);
        border-color: var(--zumba-plum);
    }
    .theme-zumba .plum.dark.disabled {
        background-color: var(--zumba-gray-800);
        border-color: var(--zumba-gray-800);
        color: var(--zumba-gray-300);
    }

    /* Magneto */
    .theme-zumba .magneto {
        font-family: var(--zumba-font-default);
        background-color: var(--zumba-white);
        border-color: var(--zumba-white);
        color: var(--zumba-gray-800);
        fill: var(--zumba-gray-800);
    }
    .theme-zumba .magneto:hover {
        background-color: var(--zumba-plum);
        border-color: var(--zumba-plum);
        color: var(--zumba-white);
        fill: var(--zumba-white);
    }
    .theme-zumba .magneto.disabled {
        background-color: var(--zumba-gray-600);
        border-color: var(--zumba-gray-600);
        color: var(--zumba-gray-400);
        fill: var(--zumba-gray-400);
    }

    /* Punisher */
    .theme-zumba .punisher {
        background-color: transparent;
        border-color: var(--zumba-white);
        color: var(--zumba-white);
        fill: var(--zumba-white);
    }
    .theme-zumba .punisher:hover {
        background-color: var(--zumba-white);
        border-color: var(--zumba-white);
        color: var(--zumba-gray-800);
        fill: var(--zumba-gray-800);
    }
    .theme-zumba .punisher.disabled {
        background-color: transparent;
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-400);
        fill: var(--zumba-gray-400);
    }

    /* Joker */
    .theme-zumba .joker {
        background-color: transparent;
        border-color: var(--zumba-neon-green);
        color: var(--zumba-neon-green);
        fill: var(--zumba-neon-green);
    }
    .theme-zumba .joker:hover {
        background-color: var(--zumba-neon-green);
        border-color: var(--zumba-neon-green);
        color: var(--zumba-gray-800);
        fill: var(--zumba-gray-800);
    }
    .theme-zumba .joker.disabled {
        background-color: transparent;
        border-color: var(--zumba-gray-400);
        color: var(--zumba-gray-400);
        fill: var(--zumba-gray-400);
    }

    .theme-zumba .text-link {
        background: none;
        border: none;
        padding: 0;
        height: unset;
        max-width: unset;
        text-decoration: none;
        color: var(--zumba-plum);
        font-family: var(--zumba-font-secondary);
    }

    .theme-zumba .text-link:hover {
        background-color: var(--zumba-white);
        color: var(--zumba-plum);
        border-color: var(--zumba-white);
    }

    /* Strong theme */

    /* Optimus */
    .theme-strong .optimus {
        background-color: var(--strong-primary-coral);
        border-color: var(--strong-primary-coral);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
    }
    .theme-strong .optimus:hover {
        background-color: var(--strong-primary-gray);
        border-color: var(--strong-primary-gray);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
    }
    .theme-strong .optimus.disabled {
        background-color: var(--strong-neutral-gray-400);
        border-color: var(--strong-neutral-gray-400);
        color: var(--strong-neutral-gray-200);
        fill: var(--strong-neutral-gray-200);
    }

    /* Robin */
    .theme-strong .robin {
        background-color: var(--strong-primary-orange);
        border-color: var(--strong-primary-orange);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
    }
    .theme-strong .robin:hover {
        background-color: var(--strong-primary-gray);
        border-color: var(--strong-primary-gray);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
    }
    .theme-strong .robin.disabled {
        background-color: var(--strong-neutral-gray-400);
        border-color: var(--strong-neutral-gray-400);
        color: var(--strong-neutral-gray-200);
        fill: var(--strong-neutral-gray-200);
    }

    /* Phoenix */
    .theme-strong .phoenix {
        border: 2px solid var(--strong-primary-coral);
        background-color: transparent;
        color: var(--strong-primary-coral);
        fill: var(--strong-primary-coral);
        box-shadow: none;
    }
    .theme-strong .phoenix:hover {
        background-color: var(--strong-primary-coral);
        border-color: var(--strong-primary-coral);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
    }
    .theme-strong .phoenix.disabled {
        background-color: transparent;
        box-shadow: none;
        border-color: var(--strong-neutral-gray-400);
        color: var(--strong-neutral-gray-400);
        fill: var(--strong-neutral-gray-400);
    }

    .theme-strong .love {
        background: unset;
        color: #FF0061;
        fill: #FF0061;
        border-color: #FF0061;
    }
    .theme-strong .love:hover {
        background-color: #FF0061;
        color: var(--zumba-white);
        border-color: #FF0061;
    }
    .theme-strong .love.dark:hover {
        background-color: var(--zumba-gray-800);
        color: #FF0061;
        border-color: #FF0061;
    }

    /* Cyclops */
    .theme-strong .cyclops {
        border: 2px solid var(--strong-primary-gray) !important;
        background-color: transparent;
        color: var(--strong-primary-gray);
        fill: var(--strong-primary-gray);
        box-shadow: none;
    }
    .theme-strong .cyclops:hover {
        background-color: var(--strong-primary-gray) !important;
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
    }
    .theme-strong .cyclops.disabled {
        background-color: transparent !important;
        box-shadow: none;
        border-color: var(--strong-neutral-gray-400) !important;
        color: var(--strong-neutral-gray-400);
        fill: var(--strong-neutral-gray-400);
    }

    /* Rogue */
    .theme-strong .rogue {
        background-color: var(--strong-neutral-gray-100);
        color: var(--strong-primary-coral);
        fill: var(--strong-primary-coral);
        border: none;
        box-shadow: none;
    }
    .theme-strong .rogue:hover {
        background-color: var(--strong-primary-gray);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
        border: none;
        box-shadow: none;
    }
    .theme-strong .rogue.disabled {
        background-color: var(--strong-neutral-gray-200);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
        border: none;
        box-shadow: none;
    }


    /* Magneto */
    .theme-strong .magneto {
        background-color: var(--strong-neutral-white);
        border-color: var(--strong-neutral-white);
        color: var(--strong-primary-gray);
        fill: var(--strong-primary-gray);
    }
    .theme-strong .magneto:hover {
        background-color: var(--strong-primary-coral);
        border-color: var(--strong-primary-coral);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
    }
    .theme-strong .magneto.disabled {
        background-color: var(--strong-neutral-gray-400);
        border-color: var(--strong-neutral-gray-400);
        color: var(--strong-neutral-gray-200);
        fill: var(--strong-neutral-gray-200);
    }

    /* Punisher */
    .theme-strong .punisher {
        background-color: transparent;
        border: 2px solid var(--strong-neutral-white);
        color: var(--strong-neutral-white);
        fill: var(--strong-neutral-white);
        box-shadow: none;
    }
    .theme-strong .punisher:hover {
        background-color: var(--strong-neutral-white);
        border-color: var(--strong-neutral-white);
        color: var(--strong-primary-gray);
        fill: var(--strong-primary-gray);
    }
    .theme-strong .punisher.disabled {
        background-color: transparent;
        border-color: var(--strong-neutral-gray-400);
        color: var(--strong-neutral-gray-400);
        fill: var(--strong-neutral-gray-400);
    }

    /* Joker */
    .theme-strong .joker {
        background-color: transparent;
        border: 2px solid var(--strong-primary-orange);
        color: var(--strong-primary-orange);
        fill: var(--strong-primary-orange);
        box-shadow: none;
    }
    .theme-strong .joker:hover {
        background-color: var(--strong-primary-orange);
        border-color: var(--strong-primary-orange);
        color: var(--strong-primary-gray);
        fill: var(--strong-primary-gray);
    }
    .theme-strong .joker.disabled {
        background-color: transparent;
        border-color: var(--strong-neutral-gray-400);
        color: var(--strong-neutral-gray-400);
        fill: var(--strong-neutral-gray-400);
    }


    /* Overrides */

    .theme-zumba .ghost,
    .theme-zumba .dark.ghost {
        background: none;
    }

    .theme-zumba .disabled,
    .theme-strong .disabled {
        cursor: not-allowed;
    }
    .theme-strong .text-link {
        background: none;
        border: none;
        padding: 0;
        height: unset;
        max-width: unset;
        text-decoration: none;
        color: var(--strong-razzmatazz);
    }

    .theme-strong .text-link:hover {
        background-color: var(--zumba-white);
        color: var(--strong-razzmatazz);
        border-color: var(--zumba-white);
    }

    .compact-always {
        height: 2.5rem;
        padding: 0 1.5rem;
        font-size: 0.875rem;
    }
    @media only screen and (max-width: 48rem) {
        .compact-mobile-only {
            height: 2.5rem;
            padding: 0 1.5rem;
            font-size: 0.875rem;
        }
    }
    @media only screen and (min-width: 48rem) {
        .compact-desktop-only {
            height: 2.5rem;
            padding: 0 1.5rem;
            font-size: 0.875rem;
        }
    }

    .super-compact-always {
        height: unset;
        padding: 0.2rem 0.5rem;
        text-transform: none;
    }
    @media only screen and (max-width: 48rem) {
        .super-compact-mobile-only {
            height: unset;
            padding: 0.2rem 0.5rem;
            text-transform: none;
        }
    }
    @media only screen and (min-width: 48rem) {
        .super-compact-desktop-only {
            height: unset;
            padding: 0.2rem 0.5rem;
            text-transform: none;
        }
    }

    .rounded-always {
        border-radius: 50%;
        padding: 1rem;
        aspect-ratio: 1;
    }
    @media only screen and (max-width: 48rem) {
        .rounded-mobile-only {
            border-radius: 50%;
            padding: 1rem;
            aspect-ratio: 1;
        }
    }
    @media only screen and (min-width: 48rem) {
        .rounded-desktop-only {
            border-radius: 50%;
            padding: 1rem;
            aspect-ratio: 1;
        }
    }
</style>
